import React from 'react';
import MyLink from '../MyLink';

const Footer = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <>
   <footer className={`w-full mx-auto pt-16 pb-4 px-3 mt-8 bg-cards text-light ${props.className}`}>
    <div className="flex justify-center">
    Copyright © 2024&nbsp; <MyLink href='https://deconpany.be'>DeConpany.be</MyLink>
    </div>
  </footer>
  </>
 
);

export default Footer;
